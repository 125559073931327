import React from "react";
import ResultLine from "./ResultLine";

interface Props {
  line: Line;
}

const SubRunResults = ({ line }: Props) => {
  if (line.subRun === null) {
    return null;
  }

  return (
    <div className="sub-run-result">
      {line.subRun.map((subRunLine, index) => {
        return (
          <ResultLine line={subRunLine} key={index} lineNumber={index + 1} />
        );
      })}
    </div>
  );
};

export default SubRunResults;
