import React, { useState, useEffect, useRef } from "react";
import Form from "./Form";
import Results from "./Results";
import useGetSharedHtaccess from "./hooks/useGetSharedHtaccess";
import useUrl from "./hooks/useUrl";
import useAnalytics from "./hooks/useAnalytics";
import { useLocation } from "react-router-dom";

const Tester = () => {
  const [requestUrl, setRequestUrl] = useState("");
  const [outputUrl, setOutputUrl] = useState("");
  const [document, setDocument] = useState("");
  const [lines, setLines] = useState<Line[]>([]);
  const doApiShareCall = useGetSharedHtaccess();
  const getQueryParameter = useUrl();
  const sendPageView = useAnalytics();
  const form = useRef<HTMLFormElement>(null);
  const [serverVariables, setServerVariables] = useState<ServerVariable[]>([
    { key: "", value: "" },
  ]);

  let location = useLocation();

  useEffect(() => {
    sendPageView(location);
  }, [location]);

  const setResult = (result: Result) => {
    setOutputUrl(result.output_url);
    setLines(result.lines);
    sendPageView();
  };

  const setShareResult = (result: Result) => {
    setRequestUrl(result.request_url);
    setOutputUrl(result.output_url);
    setLines(result.lines);
    setDocument(result.document);
    setServerVariables(
      Object.keys(result.serverVariables).map((key: string) => {
        return { key: key, value: result.serverVariables[key] };
      }),
    );
    sendPageView();
  };

  const share = getQueryParameter("share");

  useEffect(() => {
    if (share) {
      doApiShareCall(share).then(setShareResult);
    }
  }, [share]);

  return (
    <section className="tester-container">
      <h2 className="title">
        Test your htaccess <br />
        rewrite rules
      </h2>

      <div className="form-container">
        <Form
          formRef={form}
          setResult={setResult}
          lines={lines}
          serverVariables={serverVariables}
          setServerVariables={setServerVariables}
          document={document}
          setDocument={setDocument}
          requestUrl={requestUrl}
          setRequestUrl={setRequestUrl}
        />
      </div>

      {lines && lines.length > 0 && (
        <Results
          outputUrl={outputUrl}
          lines={lines}
          formRef={form}
          requestUrl={requestUrl}
          document={document}
          serverVariables={serverVariables}
        />
      )}
    </section>
  );
};

export default Tester;
