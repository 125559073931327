import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useAnalytics from "./hooks/useAnalytics";

const ApiDocs = () => {
  const sendPageView = useAnalytics();
  let location = useLocation();

  useEffect(() => {
    sendPageView(location);
  }, [location]);

  return (
    <section className="docs-container" id="home">
      <div className="title-lead-container">
        <h2 className="title docs-title">API documentation</h2>
        <p className="lead">
          To help developers build tools around the htaccess tester, we decided
          to introduce a small API.
          <br />
          We support JSON as input and output format.
        </p>
      </div>

      <h3 className="post">https://htaccess.madewithlove.com/api</h3>
      <p className="post-p">POST</p>
      <div>
        <h4 className="sub-title">Request:</h4>
        <pre className="code-container first-code-container">
          {`{
    "url": "http://example.com/test",
    "htaccess": "RewriteRule !/redirected /redirected [R]"
}`}
        </pre>
      </div>
      <div>
        <h4 className="sub-title">Response:</h4>
        <pre className="code-container">{`{
  "output_url": "http://example.com/redirected",
  "lines": [
    {
      "value": "RewriteRule !/redirected /redirected [R]",
      "isValid": true,
      "wasReached": true,
      "isMet": true,
      "message": "The new url is http://example.com/redirected Test are stopped, a redirect will be made with status code 302"
    }
  ]
}`}</pre>
      </div>
    </section>
  );
};

export default ApiDocs;
