import React, { FormEventHandler, useState } from "react";

const NEWSLETTER_API_URL = process.env.REACT_APP_NEWSLETTER_API_URL;

const NewsletterSignup = () => {
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [helpMessage, setHelpMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const changeEmail = (event: React.FormEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
    setEmailError("");
    setError("");
  };

  const addSubscription = async (email: string) => {
    setIsLoading(true);
    setError("");
    setEmailError("");
    setHelpMessage("");

    try {
      const response = await (
        await fetch(`${NEWSLETTER_API_URL}/members`, {
          method: "POST",
          body: JSON.stringify({ email }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
      ).json();

      if (response.status !== "success") {
        throw response;
      }

      return response;
    } catch (e) {
      // @ts-ignore
      if (e.status === "validation") {
        // @ts-ignore
        setEmailError(e.errors.email[0]);

        throw e;
      }

      // @ts-ignore
      setError(e.errors[0].message);
      throw e;
    }
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (!email) {
      setEmailError("Email is required");
      return;
    }

    try {
      await addSubscription(email);
      setEmail("");
      setHelpMessage("Thanks for subscribing!");
    } catch (e) {
      setError(error || "Something went wrong, please try again later");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <section className={"newsletter-signup"}>
      <div className="newsletter-signup-container">
        <h2 className={"title"}>
          Sign up for <br /> madewithlove
        </h2>
        <div className="subtitle">
          Helping SaaS startups and scale-ups <br /> and their teams with
          creating software <br /> products.
        </div>

        <form className={"newsletter-signup-form"} onSubmit={handleSubmit}>
          {error && (
            <div className="error">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                width="20"
              >
                <g>
                  <g>
                    <path
                      d="M501.362,383.95L320.497,51.474c-29.059-48.921-99.896-48.986-128.994,0L10.647,383.95
                        c-29.706,49.989,6.259,113.291,64.482,113.291h361.736C495.039,497.241,531.068,433.99,501.362,383.95z M256,437.241
                        c-16.538,0-30-13.462-30-30c0-16.538,13.462-30,30-30c16.538,0,30,13.462,30,30C286,423.779,272.538,437.241,256,437.241z
                        M286,317.241c0,16.538-13.462,30-30,30c-16.538,0-30-13.462-30-30v-150c0-16.538,13.462-30,30-30c16.538,0,30,13.462,30,30
                        V317.241z"
                    />
                  </g>
                </g>
              </svg>
              {error}
            </div>
          )}
          <div className="form-group">
            <div className="input-container">
              <input
                aria-label="Request url"
                type="email"
                className={`input--monospace ${emailError && " has-error"}`}
                name="email"
                placeholder="Your email"
                value={email}
                onChange={changeEmail}
                required
                autoComplete="off"
                autoCapitalize="off"
                spellCheck={false}
                disabled={isLoading}
              />
              <div className={`help ${emailError && " form-error"}`}>
                {emailError}
                {!emailError &&
                  (helpMessage || "No spam. Unsubscribe anytime.")}
              </div>
            </div>
          </div>
          <button
            type="submit"
            className={`button ${isLoading && " is-loading"}`}
            disabled={isLoading}
          >
            {isLoading && <span className="loader"></span>}
            Subscribe
          </button>
        </form>
      </div>
    </section>
  );
};

export default NewsletterSignup;
