import { useState } from "react";

const useHtaccessShare = (): [
  string,
  (payload: HtaccessRequest) => Promise<any>,
  (url: string) => void,
] => {
  const [url, setUrl] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;

  const getShareLink = (payload: HtaccessRequest) => {
    return fetch(`${apiUrl}/share`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(payload),
    }).then((response) => response.json());
  };

  return [url, getShareLink, setUrl];
};

export default useHtaccessShare;
