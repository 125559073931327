import clipboard from "clipboard-js";

const useClipboard = () => {
  const copyToClipboard = (data: string) => {
    clipboard.copy(data);
  };

  return copyToClipboard;
};

export default useClipboard;
