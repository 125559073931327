interface Location {
  pathname: string;
  search: string;
}

const useAnalytics = () => {
  const sendPageView = (location?: Location) => {
    location = location || window.location;

    if (typeof window.dataLayer === "object") {
      window.dataLayer.push({
        event: "pageview",
        page: location.pathname + location.search,
      });
    }
  };

  return sendPageView;
};

export default useAnalytics;
