import React from "react";
import SubRunResults from "./SubRunResults";

interface Props {
  line: Line;
  lineNumber: number;
}

const ResultLine = ({ line, lineNumber }: Props) => {
  const getClassForLine = (line: Line): string => {
    if (!line.isSupported) {
      return "rule-not-supported";
    }

    if (!line.isValid || line.isDisabled) {
      return "rule-invalid";
    }

    if (line.wasReached && line.isMet) {
      return "rule-was-met";
    }

    if (line.wasReached && !line.isMet) {
      return "rule-was-not-met";
    }

    return "rule-not-processed";
  };

  return (
    <div className={`line ${getClassForLine(line)}`}>
      <div className="line-flex-container">
        <div className="line-number">{lineNumber}</div>
        <p className="line-value">{line.value}</p>
      </div>

      {line.message.split("\n").map((item, key) => {
        return (
          <p className="line-message" key={key}>
            {item}
            <br />
          </p>
        );
      })}
      <SubRunResults line={line} />
    </div>
  );
};

export default ResultLine;
