declare var process: {
  env: {
    REACT_APP_API_URL: string;
  };
};

const useHtaccessApi = () => {
  const doApiCall = (payload: HtaccessRequest): Promise<Result> => {
    const apiUrl = process.env.REACT_APP_API_URL;

    return fetch(apiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }).then((response) => response.json());
  };

  return doApiCall;
};

export default useHtaccessApi;
