import React from "react";

interface Props {
  serverVariable: ServerVariable;
  index: number;
  changeServerVariable: (serverVariable: ServerVariable) => void;
  removeServerVariable: () => void;
}

const ServerVariable = ({
  serverVariable,
  index,
  changeServerVariable,
  removeServerVariable,
}: Props) => {
  const changeKey = (event: React.FormEvent<HTMLInputElement>) => {
    changeServerVariable({
      key: event.currentTarget.value,
      value: serverVariable.value,
    });
  };

  const changeValue = (event: React.FormEvent<HTMLInputElement>) => {
    changeServerVariable({
      key: serverVariable.key,
      value: event.currentTarget.value,
    });
  };

  return (
    <div className="form-group server-variable">
      <div className="input-container">
        <input
          aria-label="Server variable key"
          type="text"
          className="input--monospace"
          name={"server-variable-" + index}
          placeholder="e.g. HTTP_REFERER"
          value={serverVariable.key}
          autoComplete="off"
          autoCapitalize="off"
          spellCheck={false}
          onChange={changeKey}
        />
      </div>
      <div className="input-container">
        <input
          aria-label="Server variable value"
          type="text"
          className="input--monospace"
          name={"server-variable-value-" + index}
          placeholder="e.g. https://example.com"
          value={serverVariable.value}
          autoComplete="off"
          autoCapitalize="off"
          spellCheck={false}
          onChange={changeValue}
        />
      </div>
      {index !== 0 && (
        <a className="remove-variable" onClick={removeServerVariable}>
          x
        </a>
      )}
    </div>
  );
};

export default ServerVariable;
