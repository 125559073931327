const useGetSharedHtaccess = () => {
  const doApiShareCall = (shareId: string): Promise<Result> => {
    const apiUrl = process.env.REACT_APP_API_URL;

    return fetch(`${apiUrl}/share?share=${shareId}`, {
      method: "GET",
    }).then((response) => response.json());
  };

  return doApiShareCall;
};

export default useGetSharedHtaccess;
