import React, { useState } from "react";
import useClipboard from "./hooks/useClipboard";
import useHtaccessShare from "./hooks/useHtaccessShare";
import ServerVariable from "./ServerVariable";

interface Props {
  form: React.RefObject<HTMLFormElement>;
  requestUrl: string;
  document: string;
  serverVariables: ServerVariable[];
}

const ShareButton = ({
  form,
  requestUrl,
  document,
  serverVariables,
}: Props) => {
  const copyToClipboard = useClipboard();
  const [shareLink, getShareLink, setUrl] = useHtaccessShare();
  const [buttonText, setButtonText] = useState("Generate shareable link");

  const requestShareUrl = (event: React.MouseEvent) => {
    event.preventDefault();

    if (form.current) {
      const payload = {
        url: requestUrl,
        htaccess: document,
        serverVariables: serverVariables.reduce(
          (carry: ServerVariables, serverVariable: ServerVariable) => {
            carry[serverVariable.key] = serverVariable.value;
            return carry;
          },
          {},
        ),
      };
      getShareLink(payload).then((response) => {
        setUrl(response.url);
        copyToClipboard(response.url);
      });
    }
  };

  return (
    <span className="margin-left share-button-container">
      {shareLink && <div className="sr-only share-link">{shareLink}</div>}
      <button
        className="button--secondary"
        type="button"
        value="share"
        name="share"
        onClick={(event) => {
          requestShareUrl(event);
          setButtonText("Copied to clipboard!");
        }}
        onBlur={() => {
          setButtonText("Generate shareable link");
        }}
      >
        {buttonText}
      </button>
    </span>
  );
};

export default ShareButton;
